import type { ParamMatcher } from "@sveltejs/kit";

export const match = ((param) => {
  if (
    param == "studiestart" ||
    param == "student-campaign" ||
    param == "studie-start"
  ) {
    return true;
  } else {
    return false;
  }
}) satisfies ParamMatcher;
