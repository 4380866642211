import type { ParamMatcher } from "@sveltejs/kit";

export const match = ((param) => {
  const property_types = [
    // Catch all
    "lejeboliger",
    "properties-to-rent",

    // Predefined accommodation types
    "lejlighed",
    "apartment",

    "hus",
    "house",

    "værelse",
    "room",

    "townhouse",
    "rækkehus",

    // Predefined landing pages
    "studievenlig-bolig",
    "student-friendly-accommodation",
    "studiebolig",
    "student-housing",

    "seniorvenlig-bolig",
    "seniorbolig",
    "senior-friendly-accommodation",

    "co-living",
    "roommate",
    "roomie",
  ];
  return property_types.includes(param);
}) satisfies ParamMatcher;
